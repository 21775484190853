import React, { useEffect, useState } from 'react';
import { Typography, CircularProgress, Card, CardContent, Button, Grid } from '@mui/material';
import Layout from '../components/Layout';
import { getAllCourses, markCoursePublish, updateCourseStatus} from '../service/service'; // Import the service function
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();


  const fetchCourses = async () => {
    setLoading(true); // Start loading
    try {
      const data = await getAllCourses(); // Call the service function
      setCourses(data.data); // Set courses in state
    } catch (error) {
      console.error("Failed to fetch courses:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  useEffect(() => {
    fetchCourses(); // Fetch courses when the component mounts
  }, []);

  const handleEdit = (id) => {
    navigate(`/edit/${id}`); // Navigate to edit page with course ID
  };

  const handleCreateCourse = () => {
    navigate(`/create-course`)
  }


  const handleCousePublish = async (courseId) => {
    const res = await markCoursePublish({ courseId })
    if (res.message) {
      alert("Course Published Successfully");
      fetchCourses()
    } else {
      alert("Something went wrong!!")
    }

  }

  const handleCourseStatusChange = async (courseId) => {
    const res = await updateCourseStatus({ courseId })
    if (res.message) {
      alert(res.message);
      fetchCourses()
    } else {
      alert("Something went wrong!!")
    }

    
  }

  return (
    <Layout>
      {loading ? ( // Show loading indicator
        <CircularProgress />
      ) : (
        <div>
          <div className='create-container'>
              <Button variant="contained" onClick={handleCreateCourse}>Create Course</Button>
          </div>
          <Grid container spacing={2}>
            {courses.map((course) => (
              <Grid item xs={12} sm={6} md={4} key={course.id}> {/* Adjust the key according to your data structure */}
                <Card>
                  <CardContent>
                    <Typography variant="h5">{course.name}</Typography> {/* Adjust according to your course structure */}
                    <Typography variant="body2">{course.description}</Typography> {/* Adjust according to your course structure */}
                    <div className='course-action-button'>
                      <Button variant="contained" color="primary"   onClick={() => handleEdit(course._id)} style={{ marginTop: '16px' }}>
                        Edit
                      </Button>
                      {!course.isDraft && <Button variant="outlined" color="info"  style={{ marginTop: '16px' }} onClick={()=> handleCourseStatusChange(course._id)}>
                        {course.isDeleted ? 'Activate' : 'Deactivate'}
                      </Button>}
                      {course.isDraft && <Button variant="outlined" color="info"  style={{ marginTop: '16px' }}  onClick={()=> handleCousePublish(course._id)} >
                        Publish
                      </Button>}
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </Layout>
  );
};

export default Dashboard;

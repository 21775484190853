import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

const AudioPlayerComponent = ({ audioUrl }) => {
  return (
    <div className="accentify-audio-player" style={{ maxWidth: "500px", margin: "20px" }}>
      <AudioPlayer
        src={audioUrl}
        onPlay={() => console.log("Audio is playing")}
        onPause={() => console.log("Audio is paused")}
        showJumpControls={false}
        customAdditionalControls={[]}
        customVolumeControls={[]}
        autoPlayAfterSrcChange={false}
      />
    </div>
  );
};

export default AudioPlayerComponent;
